import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/blog/blog_insurance.jpg'
import insurance_value_chain from '../../images/pages/insurance_value_chain.png'
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,} from 'react-share';

var pageUrl = "";
if (typeof window !== 'undefined') {
    pageUrl = window.location.pathname
}
class SingleBlogTemplate extends React.Component{
  render(){
    return(
      <Layout>
      <SEO title="BLOG-Using RPA to improve workflow in the insurance industry." />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">Using RPA to improve workflow in the insurance industry</h1>
        </div>
      </section>

      <section id="p_blog" className="section single-blog">
          <div className="container">
              <div className="columns">
                  <div className="column is-2 share-box">
                        <h6 className="title">Share</h6>
                        <FacebookShareButton url={pageUrl} quote={"BLOG-Using RPA to improve workflow in the insurance industry" + " | " + "Nuerex"}>
                            <span className="icon facebook"></span>
                        </FacebookShareButton>
                        <LinkedinShareButton url={pageUrl} windowWidth={750}  windowHeight={600}>
                            <span className="icon linkdin"></span>
                        </LinkedinShareButton>
                        <TwitterShareButton url={pageUrl} title={"BLOG-Using RPA to improve workflow in the insurance industry" + " | " + "Nuerex"} >
                            <span className="icon twitter"></span>
                        </TwitterShareButton>
                  </div>
                  <div className="column is-10 content-area">
                      <p className="big-text">
                      Robotic Process Automation (RPA) provides an opportunity to allow businesses to quickly and 
                      efficiently reduce cost, gain efficiencies, and increase their stakeholder satisfaction.</p>
                   <p>RPA offers many bonuses for companies that primarily operate on information. In these 
                       businesses, accurate information is required to be productive and successful, for example, 
                       insurance companies. Data comes in many shapes and sizes and flows into a carrier from 
                       agents, customers, vendors, regulatory agencies, and from other sources. Applying data 
                       from specific locations to drive and route new business, underwriting, claims, billing, 
                       and services to significantly increase value. Information is the primary driver in 
                       creating value for insurance agencies, as can be seen in their value chain.</p>
                    <span className="break"></span>
                    <img src={insurance_value_chain} />
                    <span className="break"></span>
                    <h4>How Robotic Process Automation (RPA) works</h4>
                    <p>At its core, RPA works as a software technology that replicates the actions of humans by 
                        capturing and interpreting them. Though it may seem like a simple concept, current generation 
                        RPA can support and enhance employees in multiple fields. Such as data manipulation, 
                        communication, transaction processing, responding, and system communication. As a result, 
                        it excels in organizations that use people for knowledge-process work and data entry, virtually 
                        any workload that has the following traits:</p>
                    <ul>
                        <li>Repeatable</li>
                        <li>Routine</li>
                        <li>Time-sensitive</li>
                        <li>Rule-based</li>
                        <li>Error-prone</li>
                    </ul>
                    <p>RPA can perform a variety of tasks; these include simple one task processes up to complex 
                        multi-step problems. Most bots fall into one of two categories, attended and unattended. 
                        Attended bots require employees to initiate them to perform a task. However, unattended 
                        bots will wait for work to appear then immediately process it. A great many problems can 
                        be solved with RPA. RPA is not a replacement for employees; however, it is a way for 
                        employees to add value to their workflow, and may also increase job satisfaction.</p>
                    <p>Consequently, this will allow a carrier to increase productivity from its workforce. 
                        Additionally, RPA is not a Machine Learning system or an Artificial Intelligence; however, 
                        it can be used in unison with these technologies.</p>
                    <h4>Using RPA in your company</h4>
                    <p>Most leading RPA vendors make the installation and creation of RPA software easy. Most RPA 
                        toolsets typically offer a visual development environment and a user interface. Meaning that 
                        a technical background isn't necessarily required. However having an individual with such 
                        technological know-how and design experience will assist in the efficiency, capability, 
                        and scalability of this software. It is essential to approach your RPA journey thoughtfully, 
                        always think big, start small, and act quickly. Aligning your organization with RPA based on 
                        the highest value activities. Also, consider whether RPA is needed to fill gaps or it is 
                        needed for any long term strategies. Linking RPA with your strategic efforts is key to success.
                         Some other factors may include training, associate communication, ongoing maintenance, 
                         support, COE establishment, and vendor review and selection.</p>
                        
                         <div className="contact-box">
                            <h4>Talk with one of our consultants<br />
                            We're motivated and talented thinkers. Tell us the issue, and we'll find the solution.</h4>
                            <div className="button-area">
                            <Link to="/contact-us" className="button white">Get in contact</Link>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </section>

      </Layout>
    )
  }
}

export default SingleBlogTemplate
